import { lazy, Suspense } from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
const Programme = lazy(() => import('./components/Programme'));
const Lot = lazy(() => import('./components/Lot'));
const GoogleMaps = lazy(() => import('./components/GoogleMaps'));
const Visite = lazy(() => import('./components/Visite'));
const Recherche = lazy(() => import('./components/Recherche'));
const MaquetteExt = lazy(() => import('./components/Maquette/MaquetteExt'));
const ProfilLayout = lazy(() => import('./components/Profil/ProfilLayout'));
const Favorites = lazy(() => import('./components/Profil/Favorites'));
const Preferences = lazy(() => import('./components/Profil/Preferences'));
import Loading from './components/Loading';

const Routes = () => (
  <ReactRoutes>
    <Route
      path="/:mediaPath"
      element={
        <Suspense fallback={<Loading />}>
          <Programme />
        </Suspense>
      }
    >
      <Route
        path="exterieur/:mediaName"
        element={
          <Suspense fallback={<Loading />}>
            <MaquetteExt />
          </Suspense>
        }
      ></Route>
      <Route
        path="lot/:mediaName"
        element={
          <Suspense fallback={<Loading />}>
            <Lot />
          </Suspense>
        }
      ></Route>
      <Route
        path="map"
        element={
          <Suspense fallback={<Loading />}>
            <GoogleMaps />
          </Suspense>
        }
      ></Route>
      <Route
        path="visite/:mediaName"
        element={
          <Suspense fallback={<Loading />}>
            <Visite />
          </Suspense>
        }
      ></Route>
      <Route
        path="visite"
        element={
          <Suspense fallback={<Loading />}>
            <Visite />
          </Suspense>
        }
      ></Route>
      <Route
        path="recherche"
        element={
          <Suspense fallback={<Loading />}>
            <Recherche />
          </Suspense>
        }
      ></Route>
      <Route
        path="profil"
        element={
          <Suspense fallback={<Loading />}>
            <ProfilLayout />
          </Suspense>
        }
      >
        <Route
          path="favorites"
          element={
            <Suspense fallback={<Loading />}>
              <Favorites />
            </Suspense>
          }
        />
        <Route
          path="preferences"
          element={
            <Suspense fallback={<Loading />}>
              <Preferences />
            </Suspense>
          }
        />
      </Route>
    </Route>
  </ReactRoutes>
);

export default Routes;
