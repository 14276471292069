import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme';
import Routes from './routes';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router basename="/">
    <ThemeProvider>
      <Routes />
    </ThemeProvider>
  </Router>,
);
reportWebVitals();
