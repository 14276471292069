import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';
//
import palette from './palette';
import components from './components';
import typography from './typography';
// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const theme = createTheme({ palette, components, typography });
  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  );
}
