const components = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'p',
        body2: 'p',
        caption: 'p',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        transform: 'translate(12px, 10px) scale(1)',
      },
      shrink: {
        transform: 'translate(12px, -10px) scale(1)',
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        color: 'white',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: 10,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: '#fff',
        border: 'none',
        input: {
          padding: 10,
        },
      },
    },
  },
  MuiSvgIcon: {
    defaultProps: {
      fontSize: 'small',
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      title: {
        fontFamily: 'font-family-light',
        fontSize: '1.5rem',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        border: '1px solid lightgray',
      },
    },
  },
  MuiCard: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        overflow: 'unset',
        border: '1px solid lightgray',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        borderRadius: '20px',

        ...(ownerState.variant === 'outlined' &&
          ownerState.color === 'primary' && {
            backgroundColor: 'white',
          }),
      }),
      sizeLarge: {
        fontSize: '1.2rem',
        lineHeight: 1.2,
        padding: '8px 16px 8px 16px',
        minHeight: '36px',
      },
      sizeMedium: {
        fontSize: '1rem',
        lineHeight: 1,
        padding: '8px 16px 8px 16px',
        minHeight: '34px',
      },
      sizeSmall: {
        fontSize: '0.85rem',
        lineHeight: 0.85,
        padding: '4px 12px 4px 12px',
        minHeight: '30px',
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        backgroundColor: 'white',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '1.2rem',
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      valueLabel: {
        fontSize: 12,
        fontWeight: 'normal',
        top: -6,
        color: 'black',
        backgroundColor: 'unset',
        '&:before': {
          display: 'none',
        },
        '& *': {
          background: 'transparent',
        },
      },
    },
  },
};

export default components;
